import {ReactTitle} from 'react-meta-tags';

import { Link } from "react-router-dom"
import { Products } from "./Products";

const Navbar = () => {
  return (
    <div>
      <ReactTitle title="smarth"/>
      <Products />
    </div>
  );
}

export default Navbar;
