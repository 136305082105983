import React from "react";
import "./App.scss";
import { productsData } from "./productsData";

export const Products = () => {
  return (
    <>
      <div className="products d-flex m-5">
        {productsData.sort((a, b) => b.is_available - a.is_available).map((data, key) => {
          return (
            <div key={key} className="product">
              <a href={data.olx_url} className="invisible-link">
                <img className="product-image w-100 pb-3" src={`/images/products/${data.images[0]}`} alt={data.name} />
                <h1 className="product-type mb-0 text-center">{data.type}</h1>
                <h1 className="product-name text-center">{data.name}</h1>
              </a>

              <div className="info d-flex justify-content-between mt-3">
                {data.price > 0
                  ? <p className="price">
                    <span className="price">{data.price}</span>
                    ₴
                  </p>
                  : <p className="price">
                    <span className="price"> </span>
                  </p>
                }

                <p className={`stock ${data.is_available ? 'text-success' : 'text-danger'}`}>
                  <i className={`bi-${data.is_available ? 'check' : 'x'}`}></i>
                  {data.is_available ? 'Є в наявності' : 'Немає в наявності'}
                </p>
              </div>

              {data.is_available &&
                <a href={data.olx_url} className="btn btn-primary fw-bold text-white"><i className={`bi-${data.buy_icon}`}></i> {data.buy_text}</a>
              }
            </div>
          );
        })}
      </div>
    </>
  );
};
