import MetaTags from 'react-meta-tags';

import logo from './logo.svg';
import './App.scss';
import { Routes, Route} from "react-router-dom";

import Navbar from './Navbar';
import Home from './Home';
import GoogleVerification from './GoogleVerification';

function App() {
  return (
    <>
      <MetaTags>
      </MetaTags>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/googlec333ee9c0f33cd6b.html" element={<GoogleVerification />} />
      </Routes>
    </>
  );
}

export default App;
