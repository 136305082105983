export const productsData = [
  {
    type: "Датчик руху",
    manufacturer: "Aqara",
    name: "Aqara Human Body Sensor",
    path: "aqara-human-body-sensor",
    price: "470",
    is_available: true,
    buy_icon: "truck",
    buy_text: "Купити з OLX\xa0Доставкою",
    olx_url: "https://www.olx.ua/d/uk/obyavlenie/datchik-ruhu-aqara-human-body-sensor-IDRFzWf.html",
    images: [
      "aqara-human-body-sensor-1.webp"
    ]
  },
  {
    type: "Датчик дверей",
    manufacturer: "Aqara",
    name: "Aqara Door and Window Sensor",
    path: "aqara-door-and-window-sensor",
    price: "0",
    is_available: false,
    buy_icon: "truck",
    buy_text: "Купити з OLX\xa0Доставкою",
    olx_url: "",
    images: [
      "aqara-door-and-window-sensor-1.jpeg"
    ]
  },
];
